import React, { FC } from 'react';
import ProductsTable from 'pages/app/Superadmin/Products/ProductsTable';
import CreateNew from 'components/buttons/CreateNew';

const Products:FC = () => (
  <div>
    <CreateNew link="/superadmin/products/create" text="Create a Product" />
    <div className="mx-10 pt-8 px-10">
      <ProductsTable />
    </div>
  </div>
);

export default Products;
