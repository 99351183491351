import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NavIcons from 'layout/Sidebar/Navigation/NavIcon';

type Props = {
    name:string;
    to: string;
    label: string;
    active: boolean;
  }

const NavItem:FC <Props> = (props) => {
  const {
    name, to, label, active,
  } = props;
  return (
    <div>
      {active ? (
        <Link to={to} className="block mx-10 mb-2 py-3 px-6 text-lg font-bold bg-white text-primary rounded-full flex items-center space-x-3 ">
          <NavIcons name={name} color="#6165C8" />
          {' '}
          <span className="block">{label}</span>
        </Link>
      ) : (
        <Link to={to} className="block mx-10 mb-2 py-3 px-6 text-lg font-bold text-white rounded-full flex items-center space-x-3 ">
          <NavIcons name={name} color="#ffff" />
          {' '}
          <span className="block">{label}</span>
        </Link>
      )}

    </div>
  );
};

export default NavItem;
