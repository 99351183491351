import React, { FC } from 'react';

type Props = {
  status: 'unassigned' | 'pending' | 'developing' | 'rejected' | 'coming soon';
}

// DONE use :FC , destructure as props
const TicketStatus: FC <Props> = (props) => {
  const { status } = props;
  let color = '#24925F';
  if (status === 'unassigned') color = '#6E6E70';
  else if (status === 'pending') color = '#DFCC24';
  else if (status === 'developing') color = '#8BD9F1';
  else if (status === 'rejected') color = '#C86165';
  else if (status === 'coming soon') color = '#3D24DF';
  return (

    <>
      <div className="flex items-center">
        <div className="flex-shrink-0 h-5 w-5">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill={color} viewBox="0 0 24 24" stroke="none">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z" />
          </svg>
        </div>
        <div className="ml-1">
          <div className="text-sm text-gray-500">
            {status}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketStatus;
