/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { FC, useEffect, useState } from 'react';
import formatTime from 'utils/formatTime';
import formatDate from 'utils/formatDate';
import axios from 'utils/axios';
import {
  Link,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import TicketInfo from 'pages/app/Agent/UnassignedViewTicket/TicketInfo';
import Topbar from 'layout/Body/TopBar';
import Loading from 'components/Loading';
import scrollToViewElement from 'utils/scrollToViewElement';

const ViewTicket:FC = () => {
  const { ticketId }: any = useParams();
  const currentTicket = ticketId;
  const { user: currentUser }:any = useSelector<any>((state) => state.auth);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [comment, setComment] = useState('sent an attachment');
  const [ticketInfo, setTicketInfo] = useState<any>([]);
  const [ticketComments, setTicketComments] = useState<any>([]);
  const [ticketAvailable, setTicketAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    try {
      console.log('calling');
      const { data } = await axios.get(`/tickets/agent/view/${ticketId}`);
      if (data.ticketInfo.length > 0) {
        setTicketAvailable(true);
        setTicketInfo(data.ticketInfo[0]);
        setTicketComments(data.ticketComments);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDataAndScroll = async () => {
      await getData();
      // scrollToViewElement('#commentScroll');
    };
    getDataAndScroll();
    const interval = setInterval(() => {
      getData();
      console.log('refreshed');
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  // DONE use async await
  const changeHandler = async (event:any) => {
    setIsFilePicked(true);
    setSelectedFile(event.target.files[0]);
    const file = new FormData();
    await file.append('file', event.target.files[0]);

    try {
      const { data } = await axios.post('/upload', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { path, type } = data;
      setUploadedFile({ path, type });
    } catch (error) {
      console.log(error);
    }
  };

  const body = {
    ticketId: currentTicket,
    replierId: currentUser.id,
    commentText: comment,
    fileName: uploadedFile?.path,
    fileType: uploadedFile?.type,
  };
  // DONE use async await
  const submit = async (e:any) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('/tickets/comment', body);
      setTicketComments(data);
      e.target[0].value = '';
      scrollToViewElement('#commentScroll');
      setIsFilePicked(false);
      setComment('sent an attachment');
      setUploadedFile('');
      console.log('submitted');
    } catch (error) {
      console.log('error');
    }
  };
  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center">
        <div className="w-24">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <>
      {ticketAvailable ? (
        <>
          <Topbar greeting={`Ticket #${ticketId}`} />
          <TicketInfo
            ticketId={ticketId}
            ticketInfo={ticketInfo}
            repliesCount={ticketComments.length}
            ticketComments={ticketComments}
          />

          <div className="flex flex-col mx-20 my-10 pt-8 p-10 text-gray-500 text-sm h-commentHeight">
            <div className="flex-grow overflow-y-auto">

              {[...Array(ticketComments.length)].map(
                (value, index) => (
                  <>
                    <div className="flex space-x-3 mt-4">
                      {ticketComments[index].is_system === 1 ? (
                        <div className="replier flex-initial italic font-semibold text-primary">
                          {ticketComments[index].comment}
                        </div>
                      ) : (
                        <>
                          <div className="replier whitespace-nowrap flex-initial w-40">
                            {ticketComments[index].name}
                            :
                          </div>
                          <div className="comment font-semibold flex w-full break-all">
                            {ticketComments[index].comment}
                          </div>
                          <div className="commentAttachment flex-grow">

                            {ticketComments[index].file_name ? (
                              <div className="bg-primary rounded-full w-min p-0.5">
                                <a href={`${process.env.REACT_APP_FILE_URL}${ticketComments[index].file_name}`} target="_blank" rel="noreferrer">
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="#ffff">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                  </svg>

                                </a>
                              </div>
                            ) : (
                              null
                            )}

                          </div>
                          <div className="time whitespace-nowrap">
                            {formatTime(ticketComments[index].created)}
                            {' '}
                            {formatDate(ticketComments[index].created)}
                          </div>
                        </>
                      )}
                    </div>
                    {index === ticketComments.length - 1 && (
                    <div id="commentScroll" />
                    )}
                  </>
                ),
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex h-screen justify-center items-center">

          <div className="text-sm text-gray-500 text-center">
            <p>Ticket not found</p>
            <Link to="/dashboard" className="underline text-primary"><p>Go back to the Dashboard and try again</p></Link>
          </div>

        </div>

      )}

    </>

  );
};

export default ViewTicket;
