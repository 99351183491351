import React, { useState, useEffect, FC } from 'react';
import Dropdown from 'pages/app/Customer/CreateTicket/SelectProduct';
import axios from 'utils/axios';
import { useSelector } from 'react-redux';

const CreateTicket:FC = () => {
  const { user: currentUser }:any = useSelector<any>((state) => state.auth);
  const [products, setProducts] = useState<any[]>([]);

  const getProducts = async () => {
    try {
      const { data } = await axios.get(`/products/view/${currentUser.id}`);
      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      <Dropdown color="white" products={products} />
    </>
  );
};

export default CreateTicket;
