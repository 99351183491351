import React, { FC } from 'react';
import CompaniesTable from 'pages/app/Superadmin/Companies/CompaniesTable';
import CreateNew from 'components/buttons/CreateNew';

const Products:FC = () => (
  <div>
    <CreateNew link="/superadmin/companies/create" text="Create a Company" />
    <div className="mx-10 pt-8 px-10">
      <CompaniesTable />
    </div>
  </div>
);

export default Products;
