import TicketStatus from 'components/TicketStatus';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import formatTime from 'utils/formatTime';
import formatDate from 'utils/formatDate';
import { Redirect } from 'react-router-dom';

import checkCircle from 'assets/icons/checkCircle.svg';

type Props = {
  currentUser:string;
  ticketId: string;
  ticketInfo: any;
  repliesCount: any;
  ticketComments: any;
}

const TicketInfo: FC<Props> = (props) => {
  const {
    currentUser, ticketId, ticketInfo, repliesCount, ticketComments,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const imageTypes = ['.png', '.jpg', 'jpeg', '.gif', '.PNG', '.JPG', '.JPEG', '.GIF'];
  const docTypes = ['.doc', '.docx', '.pdf', '.txt', '.DOC', '.DOCX', '.PDF', '.TXT'];

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Ticket successfully deleted!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const archiveTicket = async () => {
    try {
      await axios.put(`/tickets/delete/${ticketId}`);
      setShowDeleteModal(false);
      await notify();
      setRedirect(true);
    } catch (error) {
      console.log(error);
    }
  };

  const showAttachmentsClickHandler = () => {
    setShowAttachments(true);
  };

  const hideAttachmentsClickHandler = () => {
    setShowAttachments(false);
  };

  const showDeleteModalClickHandler = () => {
    setShowDeleteModal(true);
  };

  const hideDeleteModalClickHandler = () => {
    setShowDeleteModal(false);
  };

  const archiveTicketClickHandler = () => {
    archiveTicket();
  };

  return (
    <div className="mx-20 pt-8 p-10 bg-white shadow-tableShadow rounded-3xl">
      <div className="grid grid-cols-6 gap-4 text-sm text-gray-500 ">
        <div>
          <div>Ticket Name</div>
          <div className="font-semibold truncate ...">{ticketInfo.TicketName}</div>
        </div>
        <div>
          <div>Product</div>
          <div className="font-semibold truncate ...">{ticketInfo.Product}</div>
        </div>
        <div>
          <div>Created On</div>
          <div className="font-semibold">
            {formatDate(ticketInfo.CreatedOn)}
            {' '}
            {formatTime(ticketInfo.CreatedOn)}
          </div>
        </div>
        <div>
          <div>Status</div>
          <div className="font-semibold"><TicketStatus status={ticketInfo.Status} /></div>
        </div>
        <div>
          <div>Replies</div>
          <div className="font-semibold">{repliesCount - 1}</div>
        </div>
        <div>
          <div>Agent</div>
          <div className="font-semibold truncate ...">{ticketInfo.Agent ? ticketInfo.Agent : '-'}</div>
        </div>
        <div>
          <div>Customer</div>
          <div className="font-semibold truncate ...">{currentUser}</div>
        </div>
        <div>
          <button type="button" className="flex flex-grow pt-2 text-primary underline font-semibold focus:outline-none" onClick={showAttachmentsClickHandler}>
            View More Details
          </button>
        </div>
        <div className="col-end-7">
          <button type="button" className="flex space-x-3 cursor-pointer pt-3 pr-8 focus:outline-none" onClick={showDeleteModalClickHandler}>
            <div className="font-semibold text-projectRed underline">Delete Ticket</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#C86165">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
          </button>
        </div>
      </div>

      {redirect && (
        <Redirect to="/dashboard" />
      )}

      {showDeleteModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl ">
              {/* content */}
              <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-20 py-10">
                {/* header */}
                <div className="flex items-center justify-center">
                  <h3 className="text-2xl font-bold text-primary">
                    Are you sure you want to delete this ticket?
                  </h3>
                </div>
                {/* body */}
                <div className="relative p-6 mt-10  flex items-center justify-center space-x-40">
                  <div className="flex items-center justify-center mb-5">
                    <h3 className="text-sm font-semibold text-gray-600">
                      You will not be able to reopen this ticket if you delete it.
                    </h3>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-center pt-6 space-x-3 ">
                  <button type="submit" className=" py-1 pb-1.5 px-8 bg-projectRed border border-projectRed ring-none rounded-full focus:outline-none" onClick={archiveTicketClickHandler}>
                    <span className="text-sm font-medium text-white">Delete Ticket</span>
                  </button>
                  <button type="submit" className=" py-1 pb-1.5 px-4 bg-white border border-primary rounded-full focus:outline-none" onClick={hideDeleteModalClickHandler}>
                    <span className="text-sm font-medium text-primary">Cancel and go back</span>
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}

      {showAttachments ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className="border-0 rounded-3xl shadow-lg relative bg-white outline-none focus:outline-none px-10 py-10">
                {/* header */}
                <div className="text-right">
                  <button type="submit" className="focus:outline-none" onClick={hideAttachmentsClickHandler}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#6165C8">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                </div>
                {/* body */}
                <div className="text-center text-sm text-gray-500 font-semibold">
                  Ticket #
                  {ticketId}
                </div>
                <div className="text-center text-sm text-gray-500">{ticketComments[0].comment}</div>
                {repliesCount > 1 && (
                <div className="grid grid-cols-5 gap-4  mt-5 text-sm text-gray-500 overflow-x-hidden h-96 overflow-y-auto px-2">
                  {[...Array(ticketComments.length)].map(
                    (value, index) => (
                      <>
                        {ticketComments[index].file_name
                        && imageTypes.includes(ticketComments[index].file_type) && (
                          <a href={`${process.env.REACT_APP_FILE_URL}${ticketComments[index].file_name}`} target="_blank" rel="noreferrer">
                            <div className=" rounded-lg h-28 w-28 hover:opacity-95 border-2 border-gray-500 overflow-hidden">
                              <img src={`${process.env.REACT_APP_FILE_URL}${ticketComments[index].file_name}`} alt="" className="object-cover h-28 w-full " />

                            </div>
                          </a>

                        )}
                        {ticketComments[index].file_name
                        && docTypes.includes(ticketComments[index].file_type) && (
                          <a href={`${process.env.REACT_APP_FILE_URL}${ticketComments[index].file_name}`} target="_blank" rel="noreferrer">
                            <div className="bg-primary rounded-lg h-28 w-28 hover:opacity-95 border-2 border-gray-500">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#ffff">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                              </svg>
                              <p className="text-white text-center text-semibold text-lg">{ticketComments[index].file_type}</p>

                            </div>
                          </a>

                        )}
                      </>
                    ),
                  )}
                </div>
                )}

              </div>
            </div>

          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </div>
  );
};

export default TicketInfo;
