import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
}

const ViewTicket:FC<Props> = (props) => {
  const { link } = props;
  return (

    <>
      <Link to={link} className="py-1 pb-1.5 px-4 bg-primary rounded-full">
        <span className="text-sm font-medium text-white">View Ticket</span>
      </Link>
    </>
  );
};

export default ViewTicket;
