import React, { FC } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';

type Props = {
 agentOption:any,
 changeagentOptionOption: any,
}
const ListboxOption:FC <Props> = (props) => {
  const { agentOption, changeagentOptionOption } = props;
  const classNames = (...classes:any) => classes.filter(Boolean).join(' ');
  return (
    <>
      <Listbox.Option
        key={agentOption.id}
        className={({ active }) => classNames(
          active ? 'text-white bg-indigo-600' : 'text-gray-900',
          'cursor-default select-none relative py-2 pl-3 pr-9',
        )}
        value={agentOption}
      >
        {({ selected, active }) => (
          <>
            <div role="option" className="flex items-center" onClick={() => { changeagentOptionOption(agentOption); }} onKeyPress={() => { changeagentOptionOption(agentOption); }} aria-selected tabIndex={0}>
              <span
                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
              >
                {agentOption.name}
              </span>
            </div>

            {selected ? (
              <span
                className={classNames(
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Listbox.Option>
    </>
  );
};

export default ListboxOption;
