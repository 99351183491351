import React, { FC, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import Loading from 'components/Loading';
import Sidebar from 'layout/Sidebar';
import Topbar from 'layout/Body/TopBar';

type Props = {
  exact: boolean;
  component: FC;
  path: string;
  topBarText?: string;
  routeUser: number;
  ticketView?: boolean;
};

const defaultProps = {
  topBarText: '',
  ticketView: false,
};

const PrivateRoute:FC <Props> = (props) => {
  const { isLoggedIn, user }: any = useSelector<any>((state) => state.auth);
  const { role } = user || 0;
  const { name } = user || '';
  const {
    component: Component, path, topBarText, routeUser, ticketView, exact,
  } = props;

  let renderComponent:any;

  if (!isLoggedIn) {
    renderComponent = (<Redirect to="/" />
    );
  }
  if (isLoggedIn && role === routeUser) {
    let topBarComponent:any;

    if (!ticketView && topBarText) {
      topBarComponent = (<Topbar greeting={topBarText} />);
    } else if (!ticketView && topBarText === '') { topBarComponent = (<Topbar greeting={`Hello ${name}`} />); }

    renderComponent = (
      <div className="relative min-h-screen flex bg-white">
        <Sidebar />
        <div className="flex-1 h-screen flex bg-gray-100 overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <Suspense fallback={<Loading />}>
              {topBarComponent}
              <Component />
            </Suspense>
          </div>

        </div>
      </div>
    );
  } else if (isLoggedIn) {
    renderComponent = (<Redirect to="/403" />);
  }
  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={() => (
          { ...renderComponent }
        )}
      />
    </>
  );
};

PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
