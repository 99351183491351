/* eslint-disable max-len */
import React, { FC } from 'react';

import searchIcon from 'assets/icons/search.svg';

// DONE use FC
const SearchInput: FC = () => (

  <>
    <div className="bg-white flex items-center rounded-full shadow-xl px-10 ring-2 ring-primary ring-offset-0 ">
      <input className="rounded-l-full w-full text-gray-700 leading-tight  focus:outline-none px-2 " id="search" type="text" placeholder="Search" />

      <div className="p-2">
        <button type="button" className="py-2">
          <img src={searchIcon} className="h-5 w-5" alt="Search" />
        </button>
      </div>
    </div>
  </>
);

export default SearchInput;
