const scrollToViewElement = (pointedElement:any) => {
  const element:any = document.querySelector(pointedElement);
  element.scrollIntoView({
    behavior: 'auto',
    block: 'end',
    inline: 'nearest',
  });
};

export default scrollToViewElement;
