/* eslint-disable no-unused-vars */
import {
  FC,
} from 'react';
import { Provider, useSelector } from 'react-redux';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  LOGIN_SUCCESS,
  LOGOUT,
} from 'actions/types';
import store from 'store';
import jwt from 'jsonwebtoken';

// Customer
import Dashboard from 'pages/app/Customer/Dashboard';
import CreateTicket from 'pages/app/Customer/CreateTicket';
import ViewTicket from 'pages/app/Customer/ViewTicket';

// Agent
import AgentDashboardOngoing from 'pages/app/Agent/Dashboard/Ongoing';
import AgentDashboardUnassgined from 'pages/app/Agent/Dashboard/Unassigned';
import AgentViewTicket from 'pages/app/Agent/ViewTicket/';
import AgentViewUnassignedTicket from 'pages/app/Agent/UnassignedViewTicket';

// Superadmin
import SuperadminUnassignedTickets from 'pages/app/Superadmin/Tickets/Statuses/Unassigned';
import SuperadminPendingTickets from 'pages/app/Superadmin/Tickets/Statuses/Pending';
import SuperadminDevelopingTickets from 'pages/app/Superadmin/Tickets/Statuses/Developing';
import SuperadminRejectedTickets from 'pages/app/Superadmin/Tickets/Statuses/Rejected';
import SuperadminComingSoonTickets from 'pages/app/Superadmin/Tickets/Statuses/ComingSoon';
import SuperadminCompletedTickets from 'pages/app/Superadmin/Tickets/Statuses/Completed';
import SuperadminViewTicket from 'pages/app/Superadmin/Tickets/ViewTicket';
import SuperadminViewUnassignedTicket from 'pages/app/Superadmin/Tickets/UnassignedViewTicket';

import SuperadminCustomers from 'pages/app/Superadmin/Customers';
import SuperadminCreateCustomers from 'pages/app/Superadmin/Customers/CreateCustomer';
import SuperadminAgents from 'pages/app/Superadmin/Agents';
import SuperadminCreateAgents from 'pages/app/Superadmin/Agents/CreateAgent';
import SuperadminProducts from 'pages/app/Superadmin/Products';
import SuperadminCreateProducts from 'pages/app/Superadmin/Products/CreateProduct';
import SuperadminCompanies from 'pages/app/Superadmin/Companies';
import SuperadminCreateCompanies from 'pages/app/Superadmin/Companies/CreateCompany';

// Project Manager
import ProjectManagerUnassignedTickets from 'pages/app/ProjectManager/Tickets/Statuses/Unassigned';
import ProjectManagerPendingTickets from 'pages/app/ProjectManager/Tickets/Statuses/Pending';
import ProjectManagerDevelopingTickets from 'pages/app/ProjectManager/Tickets/Statuses/Developing';
import ProjectManagerRejectedTickets from 'pages/app/ProjectManager/Tickets/Statuses/Rejected';
import ProjectManagerComingSoonTickets from 'pages/app/ProjectManager/Tickets/Statuses/ComingSoon';
import ProjectManagerCompletedTickets from 'pages/app/ProjectManager/Tickets/Statuses/Completed';
import ProjectManagerViewTicket from 'pages/app/ProjectManager/Tickets/ViewTicket';
import ProjectManagerViewUnassignedTicket from 'pages/app/ProjectManager/Tickets/UnassignedViewTicket';

// Error Pages
import NotFound from 'components/errors/NotFound';
import Forbidden from 'components/errors/Forbidden';

import Login from 'pages/auth/Login';
import ForgotPassword from 'pages/auth/PasswordReset/ForgotPassword';
import ResetPassword from 'pages/auth/PasswordReset/ResetPassword';

import setAuthToken from 'utils/setAuthToken';

import CommonRoute from 'Routers/CommonRoute';
import PrivateRoute from 'Routers/PrivateRoute';

const { token } = localStorage;

if (token) {
  setAuthToken(token);
  const decoded:any = jwt.decode(token);
  store.dispatch({
    type: LOGIN_SUCCESS,
    payload: decoded,
  });

  const { exp } = decoded;
  const currentTime = Date.now() / 1000;
  if (exp < currentTime) {
    store.dispatch({ type: LOGOUT });
  } else {
    console.log('token not expired');
  }
}

const App: FC = () => (
  <Provider store={store}>
    <ToastContainer toastStyle={{ backgroundColor: '#6165C8' }} />
    <Router>
      <Switch>
        <CommonRoute exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} routeUser={3} />
        <PrivateRoute exact path="/tickets/create" component={CreateTicket} topBarText="Create a New Ticket" routeUser={3} />
        <PrivateRoute exact path="/tickets/view/:ticketId" ticketView component={ViewTicket} routeUser={3} />

        <PrivateRoute exact path="/agent/ongoing" component={AgentDashboardOngoing} routeUser={2} />
        <PrivateRoute exact path="/agent/unassigned" component={AgentDashboardUnassgined} routeUser={2} />
        <PrivateRoute exact path="/agent/view/:ticketId" ticketView component={AgentViewTicket} routeUser={2} />
        <PrivateRoute exact path="/agent/view/unassigned/:ticketId" ticketView component={AgentViewUnassignedTicket} routeUser={2} />

        <PrivateRoute exact path="/superadmin/tickets/unassigned" component={SuperadminUnassignedTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/pending" component={SuperadminPendingTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/developing" component={SuperadminDevelopingTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/rejected" component={SuperadminRejectedTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/comingsoon" component={SuperadminComingSoonTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/completed" component={SuperadminCompletedTickets} routeUser={1} />
        <PrivateRoute exact path="/superadmin/customers" component={SuperadminCustomers} routeUser={1} />
        <PrivateRoute exact path="/superadmin/customers/create" component={SuperadminCreateCustomers} topBarText="Create a Customer" routeUser={1} />
        <PrivateRoute exact path="/superadmin/agents" component={SuperadminAgents} routeUser={1} />
        <PrivateRoute exact path="/superadmin/agents/create" component={SuperadminCreateAgents} topBarText="Create an Agent" routeUser={1} />
        <PrivateRoute exact path="/superadmin/products" component={SuperadminProducts} routeUser={1} />
        <PrivateRoute exact path="/superadmin/products/create" component={SuperadminCreateProducts} topBarText="Create a Product" routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/view/:ticketId" ticketView component={SuperadminViewTicket} routeUser={1} />
        <PrivateRoute exact path="/superadmin/tickets/view/unassigned/:ticketId" ticketView component={SuperadminViewUnassignedTicket} routeUser={1} />
        <PrivateRoute exact path="/superadmin/companies" component={SuperadminCompanies} routeUser={1} />
        <PrivateRoute exact path="/superadmin/companies/create" component={SuperadminCreateCompanies} topBarText="Create a Company" routeUser={1} />

        <PrivateRoute exact path="/projectmanager/tickets/unassigned" component={ProjectManagerUnassignedTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/pending" component={ProjectManagerPendingTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/developing" component={ProjectManagerDevelopingTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/rejected" component={ProjectManagerRejectedTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/comingsoon" component={ProjectManagerComingSoonTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/completed" component={ProjectManagerCompletedTickets} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/view/:ticketId" ticketView component={ProjectManagerViewTicket} routeUser={4} />
        <PrivateRoute exact path="/projectmanager/tickets/view/unassigned/:ticketId" ticketView component={ProjectManagerViewUnassignedTicket} routeUser={4} />

        <Route exact path="/404" component={NotFound} />
        <Route exact path="/403" component={Forbidden} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    </Router>
  </Provider>
);

export default App;
