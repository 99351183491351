/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect, FC } from 'react';
import axios from 'utils/axios';
import CurrentDate from 'utils/CurrentDate';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
  selectedProduct: string;
}

const TicketForm: FC <Props> = ({ selectedProduct }) => {
  const { user: currentUser }:any = useSelector<any>((state) => state.auth);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [problemTitle, setProblemTitle] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [submittedTicketId, setSubmittedTicketId] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  //  DONE Use async await
  const changeHandler = async (event:any) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);

    const file = new FormData();
    await file.append('file', event.target.files[0]);

    try {
      const { data } = await axios.post('/upload', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { path, type } = data;
      setUploadedFile({ path, type });
    } catch (error) {
      console.log(error);
    }
  };

  const notify = (message:any) => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>{message}</div>
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  // DONE use async await
  const submitData = async () => {
    if (selectedProduct === '') {
      notify('Please select your Product');
    } else {
      const body = {
        customerId: currentUser.id,
        productId: selectedProduct,
        ticketTitle: problemTitle,
        replierId: currentUser.id,
        commentText: problemDescription,
        fileName: uploadedFile?.path,
        fileType: uploadedFile?.type,
      };
      try {
        const response = await axios.post('/tickets/add', body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setSubmittedTicketId(response.data);
        setShowModal(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (problemDescription.length > 500) {
      setSubmitDisabled(true);
    } else { setSubmitDisabled(false); }

    console.log(selectedProduct);
  }, [problemDescription]);

  const problemTitleChangeHandler = (event:any) => {
    setProblemTitle(event.target.value);
  };

  const problemDescriptionChangeHandler = (event:any) => {
    setProblemDescription(event.target.value);
  };

  const submitTicketChangeHandler = () => {
    submitData();
  };

  const hideModalChangeHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mx-20 pt-10 pb-4 font-medium text-gray-500">Fill out the form below to submit a ticket</div>
      <div className=" mx-20 pt-8 p-10 bg-white shadow-tableShadow rounded-3xl">
        <div className="text-gray-500">What&apos;s your problem?</div>
        <div className="py-2">
          <input maxLength={40} className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="problem" type="text" placeholder="" onChange={problemTitleChangeHandler} required />
        </div>

        <div className="text-gray-500 mt-3">Describe problem in further details</div>
        <div className="py-2">
          <textarea className="shadow appearance-none bg-primaryLight border border-primary rounded-md w-full h-64 py-2 px-3  text-gray-700  focus:outline-none focus:shadow-outline" id="problemMore" placeholder="" onChange={problemDescriptionChangeHandler} required />
          <div className="text-right text-gray-500">
            {' '}
            <span className={`${(submitDisabled ? 'text-projectRed' : 'text-gray-500')}`}>{problemDescription.length}</span>
            /500
          </div>
        </div>

        <div className="text-gray-500 my-3">If you have any attachments, add them below</div>

        <div className="flex flex-row-col space-x-2">
          <div className="flex w-20 ">
            <label htmlFor="attach" className="py-1 pb-1.5 px-4 bg-gray-100 border border-primary rounded-full hover:bg-primary hover:text-white">
              Attach
            </label>
            <input id="attach" type="file" name="file" style={{ visibility: 'hidden' }} onChange={changeHandler} multiple />
          </div>

          <div className=" p-1">
            {isFilePicked ? (

              <div className="flex bg-primaryLight text-gray-400 space-x-1">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                </svg>
                <span>{selectedFile.name}</span>
              </div>

            ) : (
              <p> </p>
            )}
          </div>

        </div>

        <div className="mt-10 py-3 flex flex-row-reverse">
          <div className="block ml-4">
            <button type="submit" disabled={submitDisabled} className={`${(submitDisabled ? 'bg-unassignedGray cursor-not-allowed' : 'bg-primary')} py-1 pb-1.5 px-4 border rounded-full focus:outline-none`} onClick={submitTicketChangeHandler}>
              <span className="text-sm font-medium text-white">Submit Ticket</span>
            </button>
          </div>

          <div className="block">
            <Link to="/dashboard" type="button" className=" py-1 pb-1.5 px-4 bg-projectRed border  rounded-full focus:outline-none">
              <span className="text-sm font-medium text-white">Cancel Ticket</span>
            </Link>
          </div>

        </div>
      </div>

      <div>

        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                {/* content */}
                <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-20 py-10">
                  {/* header */}
                  <div className="flex items-center justify-center">
                    <h3 className="text-2xl font-bold text-primary">
                      Your Ticket has been submitted successfully!
                    </h3>
                  </div>
                  {/* body */}
                  <div className="relative p-6 mt-10 mb-10 flex items-center justify-center space-x-40">
                    <div className="one ">
                      <span className="flex text-sm text-gray-500 whitespace-nowrap">Ticket Number</span>
                      <span className="flex text-sm font-medium text-primary whitespace-nowrap">{submittedTicketId}</span>
                    </div>
                    <div className="two ">
                      <span className="flex text-sm text-gray-500 whitespace-nowrap">Product</span>
                      <span className="flex text-sm font-medium text-primary whitespace-nowrap">{selectedProduct}</span>
                    </div>
                    <div className="three ">
                      <span className="flex text-sm text-gray-500 whitespace-nowrap">Created On</span>
                      <span className="flex text-sm font-medium text-primary whitespace-nowrap">{CurrentDate()}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mb-5">
                    <h3 className="text-sm font-semibold text-gray-600">
                      A copy of the ticket has been sent to your email.
                    </h3>
                  </div>

                  {/* footer */}
                  <div className="flex items-center justify-center pt-6 ">
                    <Link to="/dashboard" className=" py-1 pb-1.5 px-4 bg-primary border  rounded-full" onClick={hideModalChangeHandler}>
                      <span className="text-sm font-medium text-white">Continue</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black" />
          </>
        ) : null}

      </div>
    </>
  );
};

export default TicketForm;
