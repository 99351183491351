import React, { FC } from 'react';
import TopBarGreeting from 'components/TopBarGreeting';
import SearchInput from 'components/SearchInput';
import { useDispatch } from 'react-redux';
import {
  LOGOUT,
} from 'actions/types';

type Props = {
  greeting: string;
}

// DONE use  :FC
const Topbar:FC <Props> = ({ greeting }) => {
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch({ type: LOGOUT });
  };
  return (
    <div className=" h-14 my-10 px-10 flex items-center">

      <TopBarGreeting text={greeting} />

      <div className="p-8 flex items-center space-x-4 ">

        <SearchInput />

        <div className="flex">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="#6165C8" viewBox="0 0 24 24" stroke="none">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
        </div>
        <div className="flex">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#6165C8">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

        </div>
        <div className="flex">
          <button className="focus:outline-none" type="button" onClick={logOut}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#6165C8">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </button>

        </div>
      </div>
    </div>

  );
};

export default Topbar;
