/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, FC } from 'react';
import { useTable, usePagination } from 'react-table';
import ViewTicketSmall from 'components/buttons/ViewTicketSmall';
import axios from 'utils/axios';
import formatTime from 'utils/formatTime';
import formatDate from 'utils/formatDate';
import Loading from 'components/Loading';
import AgentSelector from 'components/AgentSelector';
import TicketFilters from 'components/TicketFilters';
import UnReadCount from 'components/UnReadCount';

const Table = ({ columns, data }: any) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 7 },
      autoResetPage: false,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,

      ]);
    },
  );
  Array([pageCount]);

  const previousPageClickHandler = () => {
    previousPage();
  };

  const nextPageClickHandler = () => {
    nextPage();
  };
  // Render the UI for your table
  return (
    <>
      <div className="h-tableHeight">
        <table {...getTableProps()} className="min-w-full divide-gray-200">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-base font-bold text-gray-500 tracking-wider">{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="relative  divide-y divide-gray-200 shadow-tableShadow rounded-3xl ">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-sm">{cell.render('Cell')}</td>)}
                </tr>
              );
            })}
          </tbody>

        </table>
      </div>

      <div className="pagination mt-12">
        <div className="flex flex-col items-center">
          <div className="flex text-gray-500">
            <div className="flex  h-10 rounded-full bg-white shadow-tableShadow px-0">
              <button type="button" onClick={previousPageClickHandler} disabled={!canPreviousPage} className="font-medium w-20 md:flex justify-center items-center rounded-l-full bg-white active:bg-gray-200 hover:bg-gray-100 focus:outline-none">Previous</button>

              {[...Array(pageCount)].map(
                (value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <button
                    type="button"
                    className={
                `${(pageIndex === index ? 'bg-primary text-white ' : '')
                } w-10 md:flex justify-center items-center focus:outline-none active:bg-gray-200`
              }
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </button>

                ),
              )}

              <button type="button" onClick={nextPageClickHandler} disabled={!canNextPage} className="font-medium w-20 md:flex justify-center items-center rounded-r-full bg-white active:bg-gray-200 hover:bg-gray-100 focus:outline-none">Next</button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

const Unassigned:FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [ticketAvailable, setTicketAvailable] = useState(false);

  const getTickets = async () => {
    try {
      const { data } = await axios.get('/tickets/pm/1');
      if (data.length > 0) {
        setTickets(data);
        setTicketAvailable(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ticket No.',
        accessor: 'TicketNo',
      },
      {
        Header: 'Product',
        accessor: 'Product',
      },
      {
        Header: 'Ticket Name',
        accessor: 'TicketName',
      },
      {
        Header: 'Created On',
        accessor: 'CreatedOn',
        Cell: ({ cell: { value } }:any) => (
          <>
            {formatDate(value)}
            {' '}
            {formatTime(value)}
          </>
        ),
      },
      {
        Header: 'Assign',
        Cell: (rows:any) => {
          const { row } = rows;
          const ticketNumber = row.cells[0].value;
          return (
            <>
              <AgentSelector ticketNo={ticketNumber} />
            </>
          );
        },
      },
      {
        id: 'unReadComments',
        groupByBoundary: true,
        Header: () => (
          <>
          </>
        ),
        accessor: 'unReadComments',
        Cell: ({ cell: { value } }:any) => (
          <UnReadCount count={value} />
        ),
      },
      {
        id: 'viewTicket',
        groupByBoundary: true,
        Header: () => (
          <>
          </>
        ),
        accessor: 'TicketNo',
        Cell: ({ cell: { value } }:any) => (
          <ViewTicketSmall link={`/projectmanager/tickets/view/unassigned/${value}`} />
        ),
      },
    ],
    [],
  );

  if (isLoading) {
    return (
      <div className="flex h-96 justify-center items-center">
        <div className="w-24">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <>
      <TicketFilters activeStatus="Unassigned" isPM />
      {ticketAvailable ? (
        <div className="mx-10 pt-8 px-10">
          <Table columns={columns} data={tickets} />
        </div>
      ) : (
        <div className="flex h-96 justify-center items-center">
          {!isLoading && (
          <div className="text-sm text-gray-500 text-center">
            <p>No tickets found</p>
          </div>
          )}

        </div>

      )}
    </>

  );
};

export default Unassigned;
