import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    name:string;
    to: string;
    label: string;
    active: boolean;
  }

const SubNavItem:FC <Props> = (props) => {
  const {
    to, label, active,
  } = props;
  return (
    <div>
      {active ? (
        <Link to={to} className="block mx-16 mb-2 py-3 px-3 text-sm font-bold bg-white text-primary text-center justify-center items-center rounded-full flex items-center space-x-3 ">
          {label}
        </Link>
      ) : (
        <Link to={to} className="block mx-16 mb-2 py-3 px-3 text-sm font-bold text-white text-center justify-center items-center rounded-full flex items-center space-x-2 ">
          <span className="block">{label}</span>
        </Link>
      )}

    </div>
  );
};

export default SubNavItem;
