/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, FC, useEffect } from 'react';
import axios from 'utils/axios';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownSelect from 'components/DropdownSelect';

import checkCircle from 'assets/icons/checkCircle.svg';

const CreateCompany: FC = () => {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedCompanyTel, setSelectedCompanyTel]:any = useState('');
  const [selectedCompanyWebsite, setSelectedCompanyWebsite]:any = useState('');
  const history = useHistory();

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Customer successfully created!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const body = {
    companyAddress: selectedCompany,
    companyTel: selectedCompanyTel,
    companyWebsite: selectedCompanyWebsite,
  };

  const submitData = async () => {
    try {
      await axios.post('/companies/add', body);
      console.log(body);
      notify();
      history.push('/superadmin/companies');
    } catch (error) {
      console.log(error);
    }
  };

  const companyNameChangeHandler = (event:any) => {
    setSelectedCompany(event.target.value);
  };

  const companyTelChangeHandler = (event:any) => {
    setSelectedCompanyTel(event.target.value);
  };

  const companyWebsiteChangeHandler = (event:any) => {
    setSelectedCompanyWebsite(event.target.value);
  };

  const submitClickHandler = () => {
    submitData();
  };

  return (
    <>
      <div className="pl-96 pr-96 pt-24 items-center justify-center">
        <div className="mx-20 pt-10 pb-4 font-medium text-gray-500 text-center">Fill out the form below to create a new product</div>
        <div className=" mx-20 p-8 bg-white shadow-tableShadow rounded-3xl">
          <div className="text-gray-500">Company Name</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="companyName" type="text" placeholder="" onChange={companyNameChangeHandler} required />
          </div>

          <div className="text-gray-500">Company Tel</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="companyTel" type="text" placeholder="" onChange={companyTelChangeHandler} required />
          </div>

          <div className="text-gray-500">Company Website</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="" onChange={companyWebsiteChangeHandler} required />
          </div>

          <div className="mt-10 py-3 flex flex-row-reverse">
            <div className="block ml-4">
              <button type="submit" className=" py-1 pb-1.5 px-4 bg-primary border  rounded-full focus:outline-none" onClick={submitClickHandler}>
                <span className="text-sm font-medium text-white">Create Company</span>
              </button>
            </div>

            <div className="block">
              <Link to="/superadmin/products" type="button" className=" py-1 pb-1.5 px-4 bg-projectRed border  rounded-full focus:outline-none">
                <span className="text-sm font-medium text-white">Cancel</span>
              </Link>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default CreateCompany;
