/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import jwt from 'jsonwebtoken';

const ResetPassword:FC = () => {
  const history = useHistory();
  const [isRequestValid, setIsRequestValid] = useState(true);
  const [useremail, setUseremail] = useState('');
  let decoded:any;
  const { token }: any = useParams();
  useEffect(() => {
    if (token) {
      decoded = jwt.decode(token);
      if (decoded) {
        const { exp } = decoded;
        const { email } = decoded;
        setUseremail(email);
        const currentTime = Date.now() / 1000;
        if (exp < currentTime) {
          setIsRequestValid(false);
        } else {
          setIsRequestValid(true);
        }
      } else {
        setIsRequestValid(false);
      }
    }
  });
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [match, setMatch] = useState(true);

  const onChangePassword = (e:any) => {
    const userpassword = e.target.value;
    setPassword(userpassword);
  };

  const onChangeRepeatPassword = (e:any) => {
    const userrepeatpassword = e.target.value;
    setRepeatPassword(userrepeatpassword);
    if (password === userrepeatpassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  const notify = (message:any) => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>{message}</div>
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const handleForgot = async (e:any) => {
    e.preventDefault();
    try {
      await axios.post('/reset', {
        email: useremail,
        pass: password,
        repeatpass: repeatPassword,
        usertoken: token,
      });
      notify('Password Reset');
      history.push('/');
    } catch (err) {
      if (err?.response?.data?.message === 'Unauthorized') {
        setIsRequestValid(false);
      }
      notify(err?.response?.data?.message);
    }
  };

  return (
    <>
      {isRequestValid
        ? (
          <div className="min-h-screen flex items-center justify-center bg-primaryLight py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <form onSubmit={handleForgot} className="mt-8 space-y-6">
                <div className="form-group rounded-md shadow-sm -space-y-px">
                  <label htmlFor="password" className="flex items-center justify-center text-gray-600 my-4">Password</label>
                  <input
                    type="password"
                    className="form-control shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                  />
                </div>

                <div className="form-group rounded-md shadow-sm -space-y-px">
                  <label htmlFor="repeatPassword" className="flex items-center justify-center text-gray-600 my-4">Repeat Password</label>
                  <input
                    type="password"
                    className={`${(match ? 'border-primary' : 'border-projectRed')} form-control shadow appearance-none border bg-primaryLight rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    name="repeatPassword"
                    value={repeatPassword}
                    onChange={onChangeRepeatPassword}
                  />
                </div>

                <div className="form-group flex items-center justify-center">
                  <button type="submit" className={`${(match ? 'bg-primary' : 'bg-unassignedGray cursor-not-allowed')} my-2 hover:bg-primary text-white font-bold py-2 px-8 rounded-full focus:outline-none`} disabled={!match}>
                    <span>Reset Password</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )
        : <Redirect to="/403" />}

    </>

  );
};

export default ResetPassword;
