const formatDate = (dateString:any) => {
  const parsedDate = new Date(dateString);
  let formatedDate;
  if (parsedDate === null) {
    formatedDate = '';
  } else {
    const dd = parsedDate.getDate();
    const mm = parsedDate.getMonth() + 1; // January is 0!
    const yyyy = parsedDate.getFullYear();
    formatedDate = `${dd}/${mm}/${yyyy}`;
  }
  return formatedDate;
};

export default formatDate;
