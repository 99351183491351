import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Forbidden:FC = () => (
  <div className="min-h-screen flex items-center justify-center bg-primaryLight sm:px-6 lg:px-8">
    <div className="max-w-md w-full">
      <div className="items-center justify-center text-center text-9xl text-gray-500 font-bold">
        403
      </div>
      <div className="items-center justify-center text-center text-3xl text-primary font-semibold">
        Forbidden
      </div>
      <Link to="/" className="flex mt-10 space-x-3 items-center justify-center text-center text-xl text-primary font-semibold">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
          </svg>
        </div>
        <div> Go Back to Home</div>
      </Link>

    </div>
  </div>
);

export default Forbidden;
