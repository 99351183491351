/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

/* eslint-disable no-shadow */
import {
  FC, Fragment, useEffect, useState,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import axios from 'utils/axios';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import checkCircle from 'assets/icons/checkCircle.svg';
import ListboxOption from 'components/AgentSelector/ListboxOption';

const classNames = (...classes:any) => classes.filter(Boolean).join(' ');

type Props = {
    ticketNo: any;
};

const StatusSelector:FC<Props> = (props) => {
  const { ticketNo } = props;
  const [agents, setAgents] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>({ id: 0, name: 'Select an agent' });

  const getAgents = async () => {
    try {
      const { data } = await axios.get('users/agents');
      setAgents(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAgents();
  }, []);

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Agent successfully assigned!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const assignAgent = async (agentDetails:any) => {
    const assignAgentBody = {
      ticketId: ticketNo,
      agentId: agentDetails.id,
    };
    try {
      await axios.post('/tickets/assign', assignAgentBody);
      notify();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full rounded-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            {selected.id === 0
            && (
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >

              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {agents.map((agent:any) => (
                  <ListboxOption agentOption={agent} changeagentOptionOption={assignAgent} />
                ))}
              </Listbox.Options>
            </Transition>
            )}

          </div>
        </>
      )}
    </Listbox>
  );
};

export default StatusSelector;
