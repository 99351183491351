import React, { FC } from 'react';
import HelpDeskLogo from 'layout/Sidebar/Branding/helpdesk-logo.png';

// DONE use  :FC
const Branding: FC = () => (
  <div className="block mx-10 my-5 flex items-stretch object-fill">
    <img src={HelpDeskLogo} alt="Logo of Help Desk" />
  </div>
);

export default Branding;
