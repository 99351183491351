import React, { FC } from 'react';
import Branding from 'layout/Sidebar/Branding';
import Navigation from 'layout/Sidebar/Navigation';
// import TicketMenu from './TicketMenu';

// DONE use  :FC
const Sidebar: FC = () => (
  <>
    <div id="Navbar" className="bg-primary text-white w-64 items-center py-9">
      <div className="branding"><Branding /></div>
      <div className="nav-items my-12"><Navigation /></div>
    </div>
    {/* <div id="TicketMenu" className="flex bg-projectRed py-24 "><TicketMenu /></div> */}
  </>
);

export default Sidebar;
