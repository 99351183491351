/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, FC, useEffect } from 'react';
import axios from 'utils/axios';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownSelect from 'components/DropdownSelect';

import checkCircle from 'assets/icons/checkCircle.svg';
import DropdownMultiSelect from 'components/DropdownMultiSelect';

const CreateCustomer: FC = () => {
  const [customerName, setCustomerName] = useState('');
  const [customerCompany, setCustomerCompany]:any = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerContact, setCustomerContact] = useState('');
  const [companies, setCompanies] = useState();

  const [selectedProducts, setSelectedProducts]:any = useState([]);
  const [customers, setCustomers]:any = useState([]);

  const getCompanies = async () => {
    try {
      const { data } = await axios.get('companies');
      setCompanies(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async (companyId:any) => {
    try {
      const { data } = await axios.get(`products/view/company/${companyId}`);
      setCustomers(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanies();
    setSelectedProducts([]);
  }, [customerCompany]);

  const history = useHistory();

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Customer successfully created!</div>
      {/* DONE try to utilize an img tag with all these SVGs to benefit from caching */}
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const body = {
    cusname: customerName,
    cuscompany: customerCompany.value,
    cusemail: customerEmail,
    cuscontact: customerContact,
    cusproducts: selectedProducts,
  };
  // DONE use async await
  const submitData = async () => {
    try {
      await axios.post('/users/customers', body);
      if (selectedProducts) {
        selectedProducts.forEach((item:any) => {
          console.log(item.label);
        });
      }
      notify();
      history.push('/superadmin/customers');
      console.log(body);
    } catch (error) {
      console.log(error);
    }
  };

  const customerNameChangeHandler = (event:any) => {
    setCustomerName(event.target.value);
  };

  const customerEmailChangeHandler = (event:any) => {
    setCustomerEmail(event.target.value);
  };

  const customerContactChangeHandler = (event:any) => {
    setCustomerContact(event.target.value);
  };

  const handleChange = (selectedOption:any) => {
    getProducts(selectedOption.value);
    setCustomerCompany(selectedOption);
  };

  const handleProducts = (selectedOption:any) => {
    setSelectedProducts(selectedOption);
  };

  const submitClickHandler = () => {
    submitData();
  };

  return (
    <>
      <div className="pl-96 pr-96 pt-12 items-center justify-center">
        <div className="mx-20 pt-10 pb-4 font-medium text-gray-500 text-center">Fill out the form below to create an customer account</div>
        <div className=" mx-20 p-8 bg-white shadow-tableShadow rounded-3xl">
          <div className="text-gray-500">Customer Name</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="" onChange={customerNameChangeHandler} required />
          </div>
          <div className="text-gray-500">Customer Company</div>
          <div className="py-2">
            <DropdownSelect
              selectedValue={customerCompany}
              onChangeFunction={handleChange}
              optionsList={companies}
            />
          </div>
          <div className="text-gray-500">Assign Products</div>
          <div className="py-2">
            <DropdownMultiSelect
              selectedValue={selectedProducts}
              onChangeFunction={handleProducts}
              optionsList={customers}
            />
          </div>
          <div className="text-gray-500">Email Address</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="" onChange={customerEmailChangeHandler} required />
          </div>
          <div className="text-gray-500">Contact Number</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="contact" type="text" placeholder="" onChange={customerContactChangeHandler} required />
          </div>

          <div className="mt-10 py-3 flex flex-row-reverse">
            <div className="block ml-4">
              <button type="submit" className=" py-1 pb-1.5 px-4 bg-primary border  rounded-full focus:outline-none" onClick={submitClickHandler}>
                <span className="text-sm font-medium text-white">Create Customer</span>
              </button>
            </div>

            <div className="block">
              <Link to="/superadmin/customers" type="button" className=" py-1 pb-1.5 px-4 bg-projectRed border  rounded-full focus:outline-none">
                <span className="text-sm font-medium text-white">Cancel</span>
              </Link>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default CreateCustomer;
