import React, { FC } from 'react';
import CreateNew from 'components/buttons/CreateNew';
import TicketsTable from 'pages/app/Customer/Dashboard/TicketsTable';

// DONE Use :FC, Create fragments, remove unwanted divs
const Dashboard: FC = () => (
  <>
    <CreateNew link="/tickets/create" text="Create a New Ticket" />
    <div className=" mx-10 pt-8 px-10">
      <TicketsTable />
    </div>

  </>
);

export default Dashboard;
