import {
  FC, Fragment, useState,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import ListboxOption from 'components/StatusSelector/ListboxOption';

import checkCircle from 'assets/icons/checkCircle.svg';

const statuses = [
  {
    id: 2,
    name: 'pending',
    statusColor:
      '#DFCC24',
  },
  {
    id: 3,
    name: 'developing',
    statusColor:
      '#8BD9F1',
  },
  {
    id: 4,
    name: 'rejected',
    statusColor:
      '#C86165',
  },
  {
    id: 5,
    name: 'coming soon',
    statusColor:
      '#3D24DF',
  },
  {
    id: 6,
    name: 'completed',
    statusColor:
      '#24925F',
  },
];

type Props = {
    currentStatus: any;
    ticketNo: any;
    statusChangeHandler: any;
};

const StatusSelector:FC<Props> = (props) => {
  const { currentStatus, ticketNo, statusChangeHandler } = props;
  const currentStatusData = statuses.find((status) => {
    if (status.name === currentStatus) return true;
    return false;
  });
  const [selected, setSelected] = useState<any>(currentStatusData);

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Status successfully changed!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const changeStatus = async (statusDetails:any) => {
    const changeStatusBody = {
      ticketId: ticketNo,
      statusId: statusDetails.id,
    };
    try {
      await axios.put('/tickets/status', changeStatusBody);
      notify();
      statusChangeHandler();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="mt-1 relative w-44">
            <Listbox.Button className="relative w-full rounded-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill={selected.statusColor} viewBox="0 0 24 24" stroke="none">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z" />
                </svg>
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {statuses.map((status) => (
                  // DONE Move this to a separate component.
                  <>
                    <ListboxOption statusOption={status} changeStatusOption={changeStatus} />
                  </>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default StatusSelector;
