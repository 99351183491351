/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ForgotPassword:FC = () => {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeUsername = (e:any) => {
    const userName = e.target.value;
    setUsername(userName);
  };

  const notify = (message:any) => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>{message}</div>
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const handleForgot = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/forgot', {
        useremail: username,
      });
      setLoading(false);
      notify('Password reset link sent to email');
      history.push('/');
    } catch (err) {
      setLoading(false);
      notify(err?.response?.data?.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-primaryLight py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <form onSubmit={handleForgot} className="mt-8 space-y-6">
          <div className="form-group rounded-md shadow-sm -space-y-px">
            <label htmlFor="username" className="flex items-center justify-center text-gray-600 my-8">Enter your email address</label>
            <input
              type="text"
              className="form-control shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="username"
              value={username}
              onChange={onChangeUsername}
            />
          </div>

          <div className="form-group flex items-center justify-center">
            <button type="submit" className="my-2 bg-primary hover:bg-primary text-white font-bold py-2 px-8 rounded-full focus:outline-none" disabled={loading}>
              <span>Request Password Reset</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
