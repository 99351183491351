import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
    activeStatus:string;
    userRole:string;
    text: string;
};

const FilterLink:FC<Props> = (props) => {
  const { activeStatus, userRole, text } = props;
  return (
    <>
      <Link to={`/${userRole}/tickets/${text.toLowerCase().replace(/\s+/g, '')}`} className={`${(activeStatus === text ? 'bg-primary ' : 'bg-unassignedGray ')}  py-1 pb-1.5 px-4 rounded-full focus:outline-none`}>
        <span className="text-sm font-medium text-white">{text}</span>
      </Link>
    </>
  );
};

export default FilterLink;
