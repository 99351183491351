import React, { FC } from 'react';

type Props = {
  text: any;
}
// DONE use :FC , destructure as props
const TopBarGreeting: FC <Props> = (props) => {
  const { text } = props;
  return (
    <>
      <div className="flex-1">
        <h1 className="text-5xl font-bold text-primary">
          {text}
        </h1>
      </div>
    </>
  );
};

export default TopBarGreeting;
