import CreateNew from 'components/buttons/CreateNew';
import React, { FC } from 'react';
import CustomersTable from 'pages/app/Superadmin/Customers/CustomersTable';

const Customers:FC = () => (
  <div>
    <CreateNew link="/superadmin/customers/create" text="Create a Customer" />
    <div className="mx-10 pt-8 px-10">
      <CustomersTable />
    </div>
  </div>
);

export default Customers;
