const formatTime = (dateString:any) => {
  const parsedDate = new Date(dateString);
  let formatedTime;
  if (parsedDate === null) {
    formatedTime = '';
  } else {
    const hh = (parsedDate.getHours() < 10 ? '0' : '') + parsedDate.getHours();
    const mm = (parsedDate.getMinutes() < 10 ? '0' : '') + parsedDate.getMinutes();

    formatedTime = `${hh}:${mm}`;
  }
  return formatedTime;
};

export default formatTime;
