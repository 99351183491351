import React, { FC } from 'react';
import FilterLink from 'components/TicketFilters/FilterLink';

type Props = {
    activeStatus: string;
    isPM?: boolean;
};

const AdminTicketFilters:FC<Props> = (props) => {
  const {
    activeStatus, isPM,
  } = props;
  let userRole = 'superadmin';
  if (isPM) {
    userRole = 'projectmanager';
  }
  console.log(activeStatus);
  return (
    <div className="mx-10 px-10 space-x-3">
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Unassigned" />
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Pending" />
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Developing" />
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Rejected" />
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Coming Soon" />
      <FilterLink activeStatus={activeStatus} userRole={userRole} text="Completed" />
    </div>
  );
};

export default AdminTicketFilters;
