import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
}

const ViewTicket:FC<Props> = (props) => {
  const { link } = props;
  return (

    <>
      <Link to={link} className="flex items-center w-7 justify-center p-1 bg-primary text-white rounded-full ">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </Link>
    </>
  );
};

export default ViewTicket;
