/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, FC, useEffect } from 'react';
import axios from 'utils/axios';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DropdownSelect from 'components/DropdownSelect';

import checkCircle from 'assets/icons/checkCircle.svg';
import DropdownMultiSelect from 'components/DropdownMultiSelect';

const CreateProduct: FC = () => {
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedCompany, setSelectedCompany]:any = useState('');
  const [selectedProductType, setSelectedProductType]:any = useState('');
  const [selectedPMs, setSelectedPMs]:any = useState('');
  const [selectedDevs, setSelectedDevs]:any = useState('');

  const [companies, setCompanies]:any = useState();
  const [productTypes, setProductTypes]:any = useState();
  const [agents, setAgents]:any = useState();

  const getDropdownData = async () => {
    try {
      const { data } = await axios.get('/products/createProductDropdownDetails');
      setCompanies(data.companies);
      setProductTypes(data.products);
      setAgents(data.agents);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  const history = useHistory();

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Customer successfully created!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const body = {
    productName: selectedProductName,
    productType: selectedProductType.value,
    productCompany: selectedCompany.value,
    projectManagers: selectedPMs,
    developers: selectedDevs,
  };

  const submitData = async () => {
    try {
      await axios.post('/products/add', body);
      console.log(body);
      notify();
      history.push('/superadmin/products');
    } catch (error) {
      console.log(error);
    }
  };

  const productNameChangeHandler = (event:any) => {
    setSelectedProductName(event.target.value);
  };

  const handleCompanies = (selectedOption:any) => {
    setSelectedCompany(selectedOption);
  };

  const handleProductTypes = (selectedOption:any) => {
    setSelectedProductType(selectedOption);
  };

  const handlePMs = (selectedOption:any) => {
    setSelectedPMs(selectedOption);
  };

  const handleDevs = (selectedOption:any) => {
    setSelectedDevs(selectedOption);
  };

  const submitDataClickHandler = () => {
    submitData();
  };

  return (
    <>
      <div className="pl-96 pr-96 pt-12 items-center justify-center">
        <div className="mx-20 pt-10 pb-4 font-medium text-gray-500 text-center">Fill out the form below to create a new product</div>
        <div className=" mx-20 p-8 bg-white shadow-tableShadow rounded-3xl">
          <div className="text-gray-500">Product Name</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="" onChange={productNameChangeHandler} required />
          </div>

          <div className="text-gray-500">Product Type</div>
          <div className="py-2 ">
            <DropdownSelect
              selectedValue={selectedProductType}
              onChangeFunction={handleProductTypes}
              optionsList={productTypes}
            />
          </div>

          <div className="text-gray-500">Product Client</div>
          <div className="py-2 ">
            <DropdownSelect
              selectedValue={selectedCompany}
              onChangeFunction={handleCompanies}
              optionsList={companies}
            />
          </div>

          <div className="text-gray-500">Project Manager(s)</div>
          <div className="py-2 ">
            <DropdownMultiSelect
              selectedValue={selectedPMs}
              onChangeFunction={handlePMs}
              optionsList={agents}
            />
          </div>

          <div className="text-gray-500">Developer(s)</div>
          <div className="py-2 ">
            <DropdownMultiSelect
              selectedValue={selectedDevs}
              onChangeFunction={handleDevs}
              optionsList={agents}
            />
          </div>

          <div className="mt-10 py-3 flex flex-row-reverse">
            <div className="block ml-4">
              <button type="submit" className=" py-1 pb-1.5 px-4 bg-primary border  rounded-full focus:outline-none" onClick={submitDataClickHandler}>
                <span className="text-sm font-medium text-white">Create Product</span>
              </button>
            </div>

            <div className="block">
              <Link to="/superadmin/products" type="button" className=" py-1 pb-1.5 px-4 bg-projectRed border  rounded-full focus:outline-none">
                <span className="text-sm font-medium text-white">Cancel</span>
              </Link>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default CreateProduct;
