import React, { FC } from 'react';
import TicketsTable from 'pages/app/Agent/Dashboard/Unassigned/TicketsTable';

// DONE Use :FC, Create fragments, remove unwanted divs
const AgentDashboardUnassgined: FC = () => (
  <>
    <div className=" mx-10 pt-8 px-10">
      <TicketsTable />
    </div>

  </>
);

export default AgentDashboardUnassgined;
