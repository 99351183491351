/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, FC } from 'react';
import { useTable, usePagination } from 'react-table';
import { toast } from 'react-toastify';
import ViewTicket from 'components/buttons/ViewTicket';
import axios from 'utils/axios';
import formatTime from 'utils/formatTime';
import formatDate from 'utils/formatDate';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import StatusSelector from 'components/StatusSelector';
import TicketFilters from 'components/TicketFilters';

import checkCircle from 'assets/icons/checkCircle.svg';
import UnReadCount from 'components/UnReadCount';

const Table = ({ columns, data }: any) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 7 },
      autoResetPage: false,
    },
    usePagination,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,

      ]);
    },
  );
  Array([pageCount]);

  const previousPageClickHandler = () => {
    previousPage();
  };

  const nextPageClickHandler = () => {
    nextPage();
  };

  // Render the UI for your table
  return (
    <>
      <div className="h-tableHeight">
        <table {...getTableProps()} className="min-w-full divide-gray-200">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-base font-bold text-gray-500 tracking-wider">{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="relative  divide-y divide-gray-200 shadow-tableShadow rounded-3xl ">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 max-w-sm">{cell.render('Cell')}</td>)}
                </tr>
              );
            })}
          </tbody>

        </table>
      </div>

      <div className="pagination mt-12">
        <div className="flex flex-col items-center">
          <div className="flex text-gray-500">
            <div className="flex  h-10 rounded-full bg-white shadow-tableShadow px-0">
              <button type="button" onClick={previousPageClickHandler} disabled={!canPreviousPage} className="font-medium w-20 md:flex justify-center items-center rounded-l-full bg-white active:bg-gray-200 hover:bg-gray-100 focus:outline-none">Previous</button>

              {[...Array(pageCount)].map(
                (value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <button
                    type="button"
                    className={
                `${(pageIndex === index ? 'bg-primary text-white ' : '')
                } w-10 md:flex justify-center items-center focus:outline-none active:bg-gray-200`
              }
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </button>

                ),
              )}

              <button type="button" onClick={nextPageClickHandler} disabled={!canNextPage} className="font-medium w-20 md:flex justify-center items-center rounded-r-full bg-white active:bg-gray-200 hover:bg-gray-100 focus:outline-none">Next</button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

const Pending:FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [ticketAvailable, setTicketAvailable] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [deleteTicketId, setDeleteTicketId] = useState('');

  const getTickets = async () => {
    try {
      const { data } = await axios.get('/tickets/all/2');
      if (data.length > 0) {
        setTickets(data);
        setTicketAvailable(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTickets();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ticket No.',
        accessor: 'TicketNo',
      },
      {
        Header: 'Product',
        accessor: 'Product',
      },
      {
        Header: 'Ticket Name',
        accessor: 'TicketName',
      },
      {
        Header: 'Created On',
        accessor: 'CreatedOn',
        Cell: ({ cell: { value } }:any) => (
          <>
            {formatDate(value)}
            {' '}
            {formatTime(value)}
          </>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'Agent',
      },
      {
        Header: 'Status',
        accessor: 'Status',
        Cell: (rows:any) => {
          const { row } = rows;
          const ticketNumber = row.cells[0].value;
          const status = row.cells[5].value;
          return (
            <>
              <StatusSelector
                currentStatus={status}
                ticketNo={ticketNumber}
                statusChangeHandler={getTickets}
              />
            </>
          );
        },
      },
      {
        id: 'unReadComments',
        groupByBoundary: true,
        Header: () => (
          <>
          </>
        ),
        accessor: 'unReadComments',
        Cell: ({ cell: { value } }:any) => (
          <UnReadCount count={value} />
        ),
      },
      {
        id: 'viewTicket',
        groupByBoundary: true,
        Header: () => (
          <>
          </>
        ),
        accessor: 'TicketNo',
        Cell: ({ cell: { value } }:any) => (
          <ViewTicket link={`/superadmin/tickets/view/${value}`} />
        ),
      },
      {
        id: 'deleteTicket',
        groupByBoundary: true,
        Header: () => (
          <>
          </>
        ),
        accessor: 'TicketNo',
        Cell: ({ cell: { value } }:any) => (
          <Link to="#" onClick={() => { setShowModal(true); setDeleteTicketId(value); }}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#C86165">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </Link>
        ),
      },
    ],
    [],
  );

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Ticket successfully deleted!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const archiveTicket = async () => {
    await axios.put(`/tickets/admin/delete/${deleteTicketId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      console.log(response);
      setShowModal(false); notify(); setDeleteTicketId('');
      const newData = tickets.filter((data:any) => data.TicketNo !== deleteTicketId);
      setTickets(newData);
      console.log('deleted');
    }, (error) => {
      console.log(error);
    });
  };

  if (isLoading) {
    return (
      <div className="flex h-96 justify-center items-center">
        <div className="w-24">
          <Loading />
        </div>
      </div>
    );
  }

  const archiveTicketClickHandler = () => {
    archiveTicket();
  };

  const hideModalClickHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <TicketFilters activeStatus="Pending" />
      {ticketAvailable ? (
        <div className="mx-10 pt-8 px-10">
          <Table columns={columns} data={tickets} />
          <div>

            {showModal ? (
              <>
                <div
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                  <div className="relative w-auto my-6 mx-auto max-w-3xl ">
                    {/* content */}
                    <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-20 py-10">
                      {/* header */}
                      <div className="flex items-center justify-center">
                        <h3 className="text-2xl font-bold text-primary">
                          Are you sure you want to delete this ticket?
                        </h3>
                      </div>
                      {/* body */}
                      <div className="relative p-6 mt-10  flex items-center justify-center space-x-40">
                        <div className="flex items-center justify-center mb-5">
                          <h3 className="text-sm font-semibold text-gray-600">
                            You will not be able to reopen this ticket if you delete it.
                          </h3>
                        </div>
                      </div>
                      {/* footer */}
                      <div className="flex items-center justify-center pt-6 space-x-3 ">
                        <button type="submit" className=" py-1 pb-1.5 px-8 bg-projectRed border border-projectRed ring-none rounded-full focus:outline-none" onClick={archiveTicketClickHandler}>
                          <span className="text-sm font-medium text-white">Delete Ticket</span>
                        </button>
                        <button type="submit" className=" py-1 pb-1.5 px-4 bg-white border border-primary rounded-full focus:outline-none" onClick={hideModalClickHandler}>
                          <span className="text-sm font-medium text-primary">Cancel and go back</span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black" />
              </>
            ) : null}

          </div>
        </div>
      ) : (
        <div className="flex h-96 justify-center items-center">
          {!isLoading && (
          <div className="text-sm text-gray-500 text-center">
            <p>No tickets found</p>
          </div>
          )}

        </div>

      )}
    </>

  );
};

export default Pending;
