import React, { FC } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/solid';

type Props = {
 statusOption:any,
 changeStatusOption: any,
}
const ListboxOption:FC <Props> = (props) => {
  const { statusOption, changeStatusOption } = props;
  const classNames = (...classes:any) => classes.filter(Boolean).join(' ');
  return (
    <>
      <Listbox.Option
        key={statusOption.id}
        className={({ active }) => classNames(
          active ? 'text-white bg-indigo-600' : 'text-gray-900',
          'cursor-default select-none relative py-2 pl-3 pr-9',
        )}
        value={statusOption}
      >
        {({ selected, active }) => (
          <>
            <div role="option" className="flex items-center" onClick={() => { changeStatusOption(statusOption); }} onKeyPress={() => { changeStatusOption(statusOption); }} aria-selected tabIndex={0}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill={statusOption.statusColor} viewBox="0 0 24 24" stroke="none">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z" />
              </svg>
              <span
                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
              >
                {statusOption.name}
              </span>
            </div>

            {selected ? (
              <span
                className={classNames(
                  active ? 'text-white' : 'text-indigo-600',
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                )}
              >
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Listbox.Option>
    </>
  );
};

export default ListboxOption;
