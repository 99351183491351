/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, FC } from 'react';
import axios from 'utils/axios';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import checkCircle from 'assets/icons/checkCircle.svg';

// DONE make sure the component matches file name, change file name or change component name
// DONE check if this issue is there in other places too
const CreateAgent: FC = () => {
  const [agentName, setAgentName] = useState('');
  const [agentEmail, setAgentEmail] = useState('');
  const [agentContact, setAgentContact] = useState('');
  const history = useHistory();

  const notify = () => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>Agent successfully created!</div>
      <img src={checkCircle} className="h-5 w-5" alt="Check" />
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const body = {
    agentname: agentName,
    agentemail: agentEmail,
    agentcontact: agentContact,
  };
  // DONE use async await
  const submitData = async () => {
    try {
      await axios.post('/users/agents', body);
      notify();
      history.push('/superadmin/agents');
    } catch (error) {
      console.log(error);
    }
  };

  const agentNameChangeHandler = (event:any) => {
    setAgentName(event.target.value);
  };

  const agentEmailChangeHandler = (event:any) => {
    setAgentEmail(event.target.value);
  };

  const agentContactChangeHandler = (event:any) => {
    setAgentContact(event.target.value);
  };

  const submitDataClickHandler = () => {
    submitData();
  };

  return (
    <>
      <div className="pl-96 pr-96 pt-24 items-center justify-center">
        <div className="mx-20 pt-10 pb-4 font-medium text-gray-500 text-center">Fill out the form below to create an agent account</div>
        <div className=" mx-20 p-8 bg-white shadow-tableShadow rounded-3xl">
          <div className="text-gray-500">Agent Name</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="" onChange={agentNameChangeHandler} required />
          </div>
          <div className="text-gray-500">Email Address</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="" onChange={agentEmailChangeHandler} required />
          </div>
          <div className="text-gray-500">Contact Number</div>
          <div className="py-2">
            <input className="shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="contact" type="text" placeholder="" onChange={agentContactChangeHandler} required />
          </div>

          <div className="mt-10 py-3 flex flex-row-reverse">
            <div className="block ml-4">
              <button type="submit" className=" py-1 pb-1.5 px-4 bg-primary border  rounded-full focus:outline-none" onClick={submitDataClickHandler}>
                <span className="text-sm font-medium text-white">Create Agent</span>
              </button>
            </div>

            <div className="block">
              <Link to="/superadmin/agents" type="button" className=" py-1 pb-1.5 px-4 bg-projectRed border  rounded-full focus:outline-none">
                <span className="text-sm font-medium text-white">Cancel</span>
              </Link>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default CreateAgent;
