import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  link: string;
  text: string;
}

const CreateNew:FC <Props> = (props) => {
  const { link, text } = props;
  return (
    <div className="block h-14 block flex items-center">
      <Link to={link} className="block mx-10 py-3 px-12 text-lg font-bold bg-primary text-white rounded-full flex items-center space-x-3 ">
        <span className="block">{text}</span>
      </Link>
    </div>
  );
};

export default CreateNew;
