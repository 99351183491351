import React, { FC } from 'react';

type Props = {
  count: Number
}

// DONE use :FC , destructure as props
const UnReadCount: FC <Props> = (props) => {
  const { count } = props;
  return (
    <>
      {count && count > 0 && count <= 99 ? (
        <div className="flex items-center justify-center text-sm text-white bg-red-500 rounded-full w-7 h-7">{count}</div>
      ) : (
        <>
        </>
      )}
      {count && count > 0 && count > 99 ? (
        <div className="flex items-center justify-center text-sm text-white bg-red-500 rounded-full w-7 h-7">99+</div>
      ) : (
        <>
        </>
      )}
    </>
  );
};

export default UnReadCount;
