import axios from 'utils/axios';

const setAuthToken = (token:any) => {
  if (token) {
    // Apply auth token to every request
    axios.defaults.headers.common['x-access-token'] = token;
  } else {
    // Delete auth header if token not found
    delete axios.defaults.headers.common['x-access-token'];
  }
};

export default setAuthToken;
