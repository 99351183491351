/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useState } from 'react';
import { createPopper } from '@popperjs/core';
import TicketForm from 'pages/app/Customer/CreateTicket/TicketForm';
import { Link } from 'react-router-dom';

type Props = {
  color: string;
  products: any;
}

// eslint-disable-next-line react/prop-types
const Dropdown: FC <Props> = ({ color, products }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const [dropdownText, setDropdownText] = React.useState('Select a Product');
  const [productId, setProductId] = React.useState('');
  const btnDropdownRef: any = document.querySelector('#btnDropdownRef');
  const popoverDropdownRef: any = document.querySelector('#popoverDropdownRef');
  const [dropDownWidth, setDropDownWidth] = useState<any>();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef, popoverDropdownRef, {
      placement: 'bottom',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  // bg colors
  let bgColor;
  color === 'white'
    ? (bgColor = 'bg-primary')
    : (bgColor = `bg-${color}-500`);

  useEffect(() => {
    setDropDownWidth(document.getElementById('btnDropdownRef')?.offsetWidth);
  }, [dropdownText]);

  return (
    <>

      <div className="flex w-96 items-stretch">
        <button
          className={
          `mx-10 py-3 px-12 text-lg w-fit h-12 font-bold bg-primary text-white rounded-full  flex items-center space-x-3 focus:outline-none focus:rounded-b-none focus:rounded-t-3xl ${bgColor}`
        }
          type="button"
          id="btnDropdownRef"
          onClick={() => {
            dropdownPopoverShow
              ? closeDropdownPopover()
              : openDropdownPopover();
          }}
        >
          <div className="flex-nowrap whitespace-nowrap">{dropdownText}</div>
          <div className="flex-nowrap"><svg className="w-4 h-4 flex top-1 right-12 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#ffff" fillRule="nonzero" /></svg></div>

        </button>

        <div
          id="popoverDropdownRef"
          className={
          `${(dropdownPopoverShow ? 'block ' : 'hidden ')
          + (color === 'white' ? 'bg-white ' : `${bgColor} `)
          } text-base z-50 border-b border-l border-r border-primary rounded-b-3xl list-none text-left  shadow-lg mt-1`
        }
          style={{ width: dropDownWidth }}
        >

          {[...Array(products.length)].map(
            (value, index) => (
            // eslint-disable-next-line react/no-array-index-key
              <Link
                to={`#${products[index].id}`}
                className={
                `text-sm py-2 px-4 font-normal border-b block w-full whitespace-nowrap bg-transparent ${color === 'white' ? ' text-blueGray-700' : 'text-white'}`
              }
                onClick={() => {
                  closeDropdownPopover();
                  setDropdownText(products[index].name.toString());
                  setProductId(products[index].id.toString());
                }}
              >
                {products[index].name}
              </Link>
            ),
          )}
        </div>
      </div>

      <TicketForm selectedProduct={productId} />
    </>
  );
};

export default Dropdown;
