import {
  LOGIN_SUCCESS,
  LOGOUT,
} from 'actions/types';

import setAuthToken from 'utils/setAuthToken';

// DONE Add the user data in initial state (decoded jwt)
const initialState = {
  isLoggedIn: false,
  user: {},
};

// DONE use Arrow functions
const loginType = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS: {
      return {
        isLoggedIn: true,
        user: payload,
      }; }

    case LOGOUT: {
      setAuthToken(null);
      localStorage.removeItem('token');
      return {
        isLoggedIn: false,
        user: {},
      }; }
    default:
      return state;
  }
};

export default loginType;
