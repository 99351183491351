import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NavItem from 'layout/Sidebar/Navigation/NavItem';
import SubNavItem from 'layout/Sidebar/Navigation/SubNavItem';

// DONE use  :FC
const Navigation: FC = () => {
  const { pathname } = useLocation();
  const { ticketId }: any = useParams();

  let customerNavbar = false;
  let agentNavbar = false;
  let superadminNavbar = false;
  let projectmanagerNavbar = false;

  let ticketsActive = false;
  let faqActive = false;
  let settingsActive = false;

  let agentTicketsSection = false;
  let agentOngoingActive = false;
  let agentUnassignedActive = false;

  let superadminTicketsActive = false;
  let superadminCustomersActive = false;
  let superadminAgentsActive = false;
  let superadminProductsActive = false;
  let superadminCompaniesActive = false;

  let projectmanagerTicketsActive = false;

  switch (pathname) {
    case '/dashboard':
    case '/tickets':
    case '/tickets/create':
    case `/tickets/view/${ticketId}`:
      customerNavbar = true;
      ticketsActive = true;
      break;

    case '/faq':
      customerNavbar = true;
      faqActive = true;
      break;

    case '/settings':
      customerNavbar = true;
      settingsActive = true;
      break;

    case '/agent/ongoing':
    case `/agent/view/${ticketId}`:
      agentNavbar = true;
      agentTicketsSection = true;
      agentOngoingActive = true;
      break;

    case `/agent/view/unassigned/${ticketId}`:
      agentNavbar = true;
      agentTicketsSection = true;
      agentUnassignedActive = true;
      break;

    case '/agent/unassigned':
      agentNavbar = true;
      agentTicketsSection = true;
      agentUnassignedActive = true;
      break;

    case '/superadmin/tickets':
    case '/superadmin/tickets/unassigned':
    case '/superadmin/tickets/pending':
    case '/superadmin/tickets/developing':
    case '/superadmin/tickets/rejected':
    case '/superadmin/tickets/comingsoon':
    case '/superadmin/tickets/completed':
    case `/superadmin/tickets/view/${ticketId}`:
    case `/superadmin/tickets/view/unassigned/${ticketId}`:
      superadminNavbar = true;
      superadminTicketsActive = true;
      break;

    case '/superadmin/customers':
    case '/superadmin/customers/create':
      superadminNavbar = true;
      superadminCustomersActive = true;
      break;

    case '/superadmin/agents':
    case '/superadmin/agents/create':
      superadminNavbar = true;
      superadminAgentsActive = true;
      break;

    case '/superadmin/products':
    case '/superadmin/products/create':
      superadminNavbar = true;
      superadminProductsActive = true;
      break;

    case '/superadmin/companies':
    case '/superadmin/companies/create':
      superadminNavbar = true;
      superadminCompaniesActive = true;
      break;

    case '/projectmanager/tickets':
    case '/projectmanager/tickets/unassigned':
    case '/projectmanager/tickets/pending':
    case '/projectmanager/tickets/developing':
    case '/projectmanager/tickets/rejected':
    case '/projectmanager/tickets/comingsoon':
    case '/projectmanager/tickets/completed':
    case `/projectmanager/tickets/view/${ticketId}`:
    case `/projectmanager/tickets/view/unassigned/${ticketId}`:
      projectmanagerNavbar = true;
      projectmanagerTicketsActive = true;
      break;

    default:
      faqActive = true;
      break;
  }

  return (

    <>
      {agentNavbar && (

        <nav>
          <NavItem to="" label="Tickets" name="tickets" active={agentTicketsSection} />
          <SubNavItem to="/agent/ongoing" label="Ongoing" name="tickets" active={agentOngoingActive} />
          <SubNavItem to="/agent/unassigned" label="Unassigned" name="tickets" active={agentUnassignedActive} />
        </nav>

      )}

      {customerNavbar && (
      <nav>
        <NavItem to="/dashboard" label="Tickets" name="tickets" active={ticketsActive} />
        <NavItem to="/faq" label="FAQ" name="faq" active={faqActive} />
        <NavItem to="/settings" label="Settings" name="settings" active={settingsActive} />
      </nav>

      )}

      {superadminNavbar && (
      <nav>
        <NavItem to="/superadmin/tickets/unassigned" label="Tickets" name="tickets" active={superadminTicketsActive} />
        <NavItem to="/superadmin/companies" label="Companies" name="companies" active={superadminCompaniesActive} />
        <NavItem to="/superadmin/products" label="Products" name="products" active={superadminProductsActive} />
        <NavItem to="/superadmin/customers" label="Customers" name="customers" active={superadminCustomersActive} />
        <NavItem to="/superadmin/agents" label="Agents" name="agents" active={superadminAgentsActive} />
      </nav>

      )}

      {projectmanagerNavbar && (
      <nav>
        <NavItem to="/projectmanager/tickets/unassigned" label="Tickets" name="tickets" active={projectmanagerTicketsActive} />
      </nav>

      )}

    </>
  );
};

export default Navigation;
