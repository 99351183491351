import React, { FC, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading';

const CommonRoute:FC <any> = (props) => {
  const { component: Component, path } = props;
  const { isLoggedIn, user }: any = useSelector<any>((state) => state.auth);
  const { role } = user || 0;
  let route:any;
  switch (role) {
    case 1:
      route = '/superadmin/tickets/unassigned';
      break;

    case 2:
      route = '/agent/ongoing';
      break;

    case 3:
      route = '/dashboard';
      break;

    case 4:
      route = '/projectmanager/tickets/unassigned';
      break;

    default:
      route = '/dashboard';
      break;
  }

  return (
    <>
      <Route
        exact
        path={path}
        render={() => (
          isLoggedIn
            ? (
              <Redirect
                to={route}
              />
            )
            : (
              <>
                <Suspense fallback={<Loading />}>
                  <Component />
                </Suspense>
              </>
            ))}
      />
    </>
  );
};

export default CommonRoute;
