import CreateNew from 'components/buttons/CreateNew';
import React, { FC } from 'react';
import AgentsTable from 'pages/app/Superadmin/Agents/AgentsTable';

const Agents:FC = () => (
  <div>
    <CreateNew link="/superadmin/agents/create" text="Create an Agent" />
    <div className="mx-10 pt-8 px-10">
      <AgentsTable />
    </div>

  </div>
);

export default Agents;
