/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import HelpDeskLogo from 'layout/Sidebar/Branding/helpdesk-logo-purple.png';
import axios from 'utils/axios';
import {
  LOGIN_SUCCESS,
} from 'actions/types';
import jwt from 'jsonwebtoken';
import setAuthToken from 'utils/setAuthToken';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Login:FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeUsername = (e:any) => {
    const userName = e.target.value;
    setUsername(userName);
  };

  const onChangePassword = (e:any) => {
    const userPass = e.target.value;
    setPassword(userPass);
  };

  const notify = (errorMessage:any) => toast.dark(
    <div className="flex space-x-3 items-center justify-center">
      <div>{errorMessage}</div>
    </div>, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );

  const handleLogin = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response:any = await axios.post('/signin', {
        useremail: username,
        userpw: password,
      });
      const { data } = response;
      const { token } = data;
      setAuthToken(token);
      setLoading(false);
      localStorage.setItem('token', token);
      const decoded:any = jwt.decode(token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: decoded,
      });
    } catch (err) {
      setLoading(false);
      notify(err?.response?.data?.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-primaryLight py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-10 w-auto"
            src={HelpDeskLogo}
            alt="Workflow"
          />
        </div>
        <form onSubmit={handleLogin} className="mt-8 space-y-6">
          <div className="form-group rounded-md shadow-sm -space-y-px">
            <label htmlFor="username" className="flex items-center justify-center text-gray-600 my-3">Username</label>
            <input
              type="text"
              className="form-control shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="username"
              value={username}
              onChange={onChangeUsername}
            />
          </div>

          <div className="form-group rounded-md shadow-sm space-y-px">
            <label htmlFor="password" className="flex items-center justify-center text-gray-600 my-3">Password</label>
            <input
              type="password"
              className="form-control shadow appearance-none border bg-primaryLight border-primary rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="password"
              value={password}
              onChange={onChangePassword}
            />
          </div>

          <div className="form-group flex items-center justify-center">
            <button type="submit" className="my-2 bg-primary hover:bg-primary text-white font-bold py-2 px-8 rounded-full focus:outline-none" disabled={loading}>
              <span>Login</span>
            </button>
          </div>
        </form>
        <div className="text-center font-sm text-primary">
          <span>Forgot your password?</span>
          <Link to="/forgot">
            <span className="underline"> Click Here</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
