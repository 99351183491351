import React, { FC } from 'react';
import Select from 'react-select';

type Props = {
    selectedValue: any;
    onChangeFunction: any;
    optionsList: any;
}

const DropdownSelect:FC<Props> = (props) => {
  const {
    selectedValue, onChangeFunction, optionsList,
  } = props;
  return (
    <>
      <Select
        value={selectedValue}
        isMulti
        onChange={onChangeFunction}
        options={optionsList}
        className="basic-multi-select"
        classNamePrefix="select"
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary25: '#F7F8FB',
            primary: '#6165C8',
            primary50: '#F7F8FB',
            neutral30: '#6165C8',
            neutral20: '#6165C8',
            neutral80: '#374151',

          },
        })}
      />
    </>
  );
};

export default DropdownSelect;
